import React, { FormEvent } from 'react';
import './Login.scss';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from '../../../hooks';
import TextField from '../../TextField/TextField';
import Button from '../../Button/Button';
import { useUserStore } from '../../../stores';
import { observer } from 'mobx-react-lite';

export default observer(() => {
    const userStore = useUserStore();
    const history = useHistory();
    const { values, useInput, isValid } = useForm({
        username: '',
        password: ''
    });

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!isValid) return;

        userStore.login(values).then((response: any) => {
            history.push('/');
        });
    }

    return (
        <div className="login-form">
            <form onSubmit={onSubmit}>
                <TextField  
                        label="Email" 
                        name="username" 
                        required
                        type="email" 
                        useInput={useInput('username', {
                            isRequired: true,
                            isEmail: true
                        })}/>
                <TextField  
                        label="Password" 
                        name="password" 
                        required
                        type="password" 
                        useInput={useInput('password', 'isRequired')}/>
                <div className="login-form__form-footer">
                    <div className="login-form__form-footer__font">
                        <span className="login-form__form-footer__font__text">Sign In</span>
                    </div>
                    <Button disabled={!isValid} isFloating={true} type="submit" icon={['fas', 'angle-right']}></Button>
                </div>
            </form>
            <div className="login-form__footer-links">
                <Link className="a a--black" to="/register">Sign up</Link>
                <Link className="a a--black" to="/forgot-password">Forgot password</Link>
            </div>
        </div>
    )
});
