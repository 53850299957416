import { useEffect, useState, useCallback } from 'react';
import useFormInput from './form-input';


export default function useForm (defaultValues : any, invalidAttr: any = { error: true }) {
    const [values, setValues] = useState<any>(defaultValues);
    const [mounted, setMounted] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<string[]>([]);
  
    const handleError = useCallback((name: string, isValid: boolean) => {
      let errors: string[] = formErrors;
      const index = errors.findIndex(error => error === name);
  
      if (!isValid) {
        if (index < 0) errors.push(name);
      } else {
        if (index > -1) errors.splice(index, 1);
      }
  
      setFormErrors(errors);
    }, [formErrors]);
  
    useEffect(() => {
      setMounted(true);
    }, []);
  
    const useInput = (name: string, validation: any) => useFormInput({
      name,
      validation,
      values,
      setValues,
      defaultInvalidAttr: invalidAttr,
      handleError
    });
    return {
      values,
      setValues,
      useInput,
      errors: formErrors,
      isValid: mounted && !formErrors.length
    };
  }