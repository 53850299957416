import { observable, action } from 'mobx';
import ShelfInterface from '../models/shelf';

class ShelfStore {

    @observable shelves: ShelfInterface[] = [];
		
	@action remove(id: string = '-1') {
		this.shelves.forEach((shelf, index) => {
			if (shelf.id === id) {
				this.shelves.splice(index);
			}
		});
	}

	@action setItem(shelf: ShelfInterface) {
		this.shelves.forEach((item, index) => {
			if (item.id === shelf.id) {
				this.shelves[index] = shelf;
			}
		});
	}

	@action add(shelf: ShelfInterface) {
		this.shelves.push(shelf);
	}

	@action set(shelves: ShelfInterface[]) {
		this.shelves = shelves;
	}
}

export default new ShelfStore();