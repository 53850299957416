import React, { useState, useEffect } from 'react';
import Loader from '../Loader/Loader';
import bookAPI from '../../api/book';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from 'react-router';
import { useBookStore } from '../../stores';
import BookItem from '../BookItem/BookItem';
import Button from "../Button/Button";
import './BookList.scss';
import BookInterface from "../../models/book";

export default ({books, libraryId, buttonLink, buttonText} : {books: BookInterface[]; libraryId?: string; buttonLink: string; buttonText: string}) => {

	return (
		<div className="library-list book-list">
			<div>
				<Button to={buttonLink} icon={['fas', 'pallet']} size="1x" >{buttonText}</Button>
			</div>
			<ul className="library-list__ul">
				{books.map(book => (<BookItem key={book.id + book.shelf.id + book.row} libraryId={libraryId} book={book}/>))}
			</ul>
		</div>
	)
}