import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useUserStore } from '../../stores';
import { observer } from 'mobx-react-lite';


export default observer(({ component, ...rest }: RouteProps) => {
    const userStore = useUserStore();

    const redirect = () => (
        <Redirect to="/login"></Redirect>
    )

    if (userStore.user) {
        return (
            <Route {...rest} component={component}></Route>
        )
    } else {
        return (
            <Route {...rest} component={redirect}></Route>
        )
    }
})