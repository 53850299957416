import React, {useEffect, useState} from 'react';
import Button from '../../components/Button/Button';
import BookList from '../../components/BookList/BookList';
import bookAPI from "../../api/book";
import {useBookStore} from "../../stores";
import {useHistory, useParams} from "react-router";
import Loader from "../../components/Loader/Loader";
import {observer} from "mobx-react-lite";

export default observer(() => {
    const bookStore = useBookStore();
    const history = useHistory();
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(true);


    if (!id) {
        history.push('/');
    }

    useEffect(() => {
        bookAPI.all(id).then((response) => {
            setIsLoading(false);
            bookStore.set(response);
        })
    }, []);

    return (
        <Loader isLoading={isLoading}>
            <div className="container">
                <BookList books={bookStore.books} libraryId={id} buttonLink={`/library/${id}/shelves`} buttonText="Nach Regalen gruppieren" />
                <div className="button-menu">
                    <Button className="shelf-button" isFloating icon={['fas', 'plus']}/>
                </div>
            </div>
        </Loader>
    );
})