import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router";
import bookAPI from "../../api/book";
import {Requester} from "../../util";
import Loader from "../../components/Loader/Loader";
import './BookDetail.scss';

export default () => {
	const { id } = useParams();
	const history = useHistory();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ book, setBook] = useState<any>(null)
	const apiURL = Requester.baseUrl;
	if (!id) {
		history.push('/');
		return null;
	}

	useEffect(() => {
		bookAPI.single(id).then((response) => {
			setIsLoading(false);
			setBook(response);
		})
	}, []);

	return (
		<div className="container">
			<Loader isLoading={isLoading}>
				{book && <div className="book-detail">
					<div className="book-detail__header">
						<div className="book-detail__image-wrapper">
							<img className="book-detail__image" alt={book.title}
								 src={(apiURL ? apiURL : '') + (book.image.path ? book.image.path : '')}/>
						</div>
						<div className="book-detail__intro">
							<h1 className="book-detail__title">{book.title}</h1>
							{book.tagline && <div className="book-detail__tagline">{book.tagline}</div>}
							{book.authors && (
								<div className="book-item__detail-info-item">
									<strong className="book-item__detail-info-item-label">Autor: </strong>
									<span className="book-item__detail-info-item-value">{book.authors.join(', ')}</span>
								</div>
							)}
							{book.pages && (
								<div className="book-item__detail-info-item">
									<strong className="book-item__detail-info-item-label">Seiten: </strong>
									<span className="book-item__detail-info-item-value">{book.pages}</span>
								</div>
							)}
							{book.datePublished && (
								<div className="book-item__detail-info-item">
									<strong className="book-item__detail-info-item-label">Veröffentlicht: </strong>
									<span className="book-item__detail-info-item-value">{book.datePublished}</span>
								</div>
							)}
							{book.tags && <div className="book-detail__tags">
								{book.tags.map((tag: string) => (
									<span className="book-detail__tag" >{tag}</span>
								))}
							</div>}
						</div>
					</div>
					<div className="book-detail__description">
						{book.description}
					</div>
				</div>}
			</Loader>
		</div>
	);
}