import { Requester } from '../util';
import LibraryInterface from '../models/library';
import BookInterface from "../models/book";

export default {
    async all(library?: string) : Promise<any> {
        return await Requester.get(`/library/${library}/books`, {});
    },

    async shelf(library?: string, shelf?: string) : Promise<any> {
        return await Requester.get(`/library/${library}/shelves/${shelf}`, {});
    },

    async single(id: string): Promise<BookInterface> {
        return await Requester.get(`/book/${id}`, {});
    }
}