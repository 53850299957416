import { Requester } from '../util';
import LoginRequestInterface from '../models/login-request';
import UserInterface from '../models/user';

export default {
    async login(user: LoginRequestInterface) : Promise<any> { 
        const response = await Requester.post('/login', user, {});

        return response.token;
    },

    async me() : Promise<UserInterface> {
        const user = await Requester.get('/user', {});

        return user;
    },

    async create(user: UserInterface) : Promise<UserInterface> {
        const response = await Requester.put('/user', user, {});

        return response;
    }
}