import React from 'react';
import { observer } from 'mobx-react-lite';
import LibraryList from '../../components/LibraryList/LibraryList';
import Button from '../../components/Button/Button';
import './LibraryNew.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModifyLibrary from '../../components/Forms/ModifyLibrary/ModifyLibrary';

export default observer(() => (
    <div className="container">
        <div className="container__inner">
            <h2 className="h2">Create a New Libray</h2>
            <ModifyLibrary />
        </div>
    </div>
))