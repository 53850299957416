import React from 'react';
import userStore from './user-store';
import globalStore from './global-store';
import libraryStore from './library-store';
import shelfStore from './shelf-store';
import bookStore from './book-store';

const StoreContext = React.createContext({
    userStore,
	globalStore,
	libraryStore,
	shelfStore,
	bookStore,
});

export const useUserStore = () => {
    return React.useContext(StoreContext).userStore;
}

export const useGlobalStore = () => {
    return React.useContext(StoreContext).globalStore;
}

export const useBookStore = () => {
    return React.useContext(StoreContext).bookStore;
}


export const useShelfStore = () => {
	return React.useContext(StoreContext).shelfStore;
}

export const useLibraryStore = () => {
    return React.useContext(StoreContext).libraryStore;
}

export default StoreContext
