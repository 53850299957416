import React, { ButtonHTMLAttributes } from 'react';
import './Button.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import {SizeProp} from "@fortawesome/fontawesome-svg-core";
import {Link} from "react-router-dom";

interface ButtonInterface extends ButtonHTMLAttributes<HTMLElement> {
    icon?: any[],
    isFloating?: boolean,
    isClear?: boolean,
    size?: SizeProp,
    to?: string,
}

export default observer(({ children, isFloating = false, isClear = false, className = '', icon, size='2x', to, ...rest }: ButtonInterface) => {
    const Button = to ? Link : 'button';
    if (to) {
        return (
          <Link to={to} className={
              classNames({
                  'btn': true,
                  'btn--floating': isFloating,
                  'btn--clear': isClear
              }) + (className !== '' ? ' ' + className : '')
          }>
              {icon ? (
                  <FontAwesomeIcon className="btn__icon" size={size} icon={[icon[0], icon[1]]}/>) : null}{children}
          </Link>
        );
    } else {

        return (
            <button {...rest} className={
                classNames({
                    'btn': true,
                    'btn--floating': isFloating,
                    'btn--clear': isClear
                }) + (className !== '' ? ' ' + className : '')
            }>{icon ? (
                <FontAwesomeIcon className="btn__icon" size={size} icon={[icon[0], icon[1]]}/>) : null}{children}</button>
        );
    }

});