import React, { FormEvent } from 'react';
import { useForm } from '../../../hooks';
import TextField from '../../TextField/TextField';
import libraryAPI from '../../../api/library';
import './ModifyLibrary.scss';
import Button from '../../Button/Button';
import { observer } from 'mobx-react-lite';
import LibraryInterface from '../../../models/library';
import { useHistory } from 'react-router';

export default observer(() => {
    const history = useHistory();
    const { values, useInput, isValid } = useForm({
        name: ''
    });

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!isValid) return;
        libraryAPI.create(values).then((response: LibraryInterface) => {
            history.push('/');
        });
    }

    return (
        <div className="modify-library-form">
            <form onSubmit={onSubmit}>
                <TextField  
                        label="Name:" 
                        name="name" 
                        required
                        type="text" 
                        useInput={useInput('name', {
                            isRequired: true
                        })}/>
                <div className="modify-library-form__form-footer">
                    <Button className="plus--floating" disabled={!isValid} isFloating={true} type="submit" icon={['fas', 'plus']}></Button>
                </div>
            </form>
        </div>
    )
});
