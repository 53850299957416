import React from 'react';
import { useGlobalStore } from './stores/index';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import { usePadding } from './hooks';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import Login from './views/Login/Login';
import Register from './views/Register/Register';
import Landing from './views/Landing/Landing';
import LibraryNew from './views/LibraryNew/LibraryNew';
import { observer } from 'mobx-react-lite';
import Shelves from './views/Shelves/Shelves';
import Books from './views/Books/Books';
import BookDetail from "./views/BookDetail/BookDetail";
import ShelfBooks from "./views/ShelfBooks/ShelfBooks";

export default observer(() => (
    <Router>
        <RoutesWrapper />
    </Router>
))

const RoutesWrapper = observer(() => {
    const history = useHistory();
    const globalStore = useGlobalStore();
    const padding = usePadding(history.location.pathname);
    
    globalStore.padding = padding;

    return (
        <div className="routes-wrapper">
            <Route exact path="/login" component={Login}/>
            <Route exact path="/register" component={Register}/>
            <ProtectedRoute exact path="/library/:id/shelves" component={Shelves}/>
            <ProtectedRoute exact path="/library/:id/shelves/:shelfId/books" component={ShelfBooks}/>
            <ProtectedRoute exact path="/library/:id/books" component={Books}/>
            <ProtectedRoute exact path="/book/:id" component={BookDetail}/>
            <ProtectedRoute exact path="/" component={Landing}/>
            <ProtectedRoute exact path="/library/new" component={LibraryNew}/>
        </div>
    )
});