import { observable, action } from 'mobx';
import LibraryInterface from '../models/library';

class LibraryStore {

    @observable libraries: LibraryInterface[] = [];
		
	@action remove(id: string = '-1') {
		this.libraries.forEach((library, index) => {
			if (library.id === id) {
				this.libraries.splice(index);
			}
		});
	}

	@action setItem(library: LibraryInterface) {
		this.libraries.forEach((item, index) => {
			if (item.id === library.id) {
				this.libraries[index] = library;
			}
		});
	}

	@action add(library: LibraryInterface) {
		this.libraries.push(library);
	}

	@action set(libraries: LibraryInterface[]) {
		this.libraries = libraries;
	}
}

export default new LibraryStore();