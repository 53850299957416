import userStore from '../stores/user-store';
import { Cookies } from '../util';

class Requester {
    baseUrl?: string = '';
    instance: any = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };

    pendingRequests: any = {
        get: {},
        post: {},
        put: {},
        delete: {}
    };

    constructor() {
        this.baseUrl = process.env.REACT_APP_API_URL;
    }

    async get(path: string, headers: any, abort: boolean = true): Promise<any> {
        this.auth();

        const abortController = new AbortController();
        const signal = abortController.signal;

        this.instance = {
            method: 'GET',
            signal: signal,
            headers: {
                ...headers,
                ...this.instance.headers
            }
        }

        if (abort && this.pendingRequests['get'][path]) {
            this.pendingRequests['get'][path].abort();
        }

        this.pendingRequests['get'][path] = abortController;

        let response = await fetch(this.baseUrl + path , this.instance);

        delete this.pendingRequests['get'][path];

        try {
            this.checkStatus(response.status);
        } catch(e) {
            throw e;
        }

        response = await response.json();

        return response;
    }

    auth() {
        const token = Cookies.get('token');

        if (token) {
            this.instance.headers['Authorization'] = 'Bearer ' + token;
        }
    }

    checkStatus(status: any) {
        switch(status) {
            case 200:
            case 300:
            case 201:
            case 204:
                break;
            case 401:
            case 403:
                userStore.logout();
                break;
            default:
                throw new Error('something went wrong');
        }
    }

    async post(path: string, parameter: any, headers: any, abort: boolean = true): Promise<any> {
        const abortController = new AbortController();
        const signal = abortController.signal;

        this.instance = {
            method: 'POST',
            body: JSON.stringify(parameter),
            signal: signal,
            headers: {
                ...headers,
                ...this.instance.headers
            }
        }

        if (abort && this.pendingRequests['post'][path]) {
            this.pendingRequests['post'][path].abort();
        }

        this.pendingRequests['post'][path] = abortController;

        const response = await fetch(this.baseUrl + path, this.instance);

        delete this.pendingRequests['post'][path];

        try {
            this.checkStatus(response.status);
        } catch(e) {
            throw e;
        }

        let body = await response.json();

        return body;
    }

    async put(path: string, parameter: any, headers: any, abort: boolean = true): Promise<any> {
        this.auth();

        const abortController = new AbortController();
        const signal = abortController.signal;

        this.instance = {
            method: 'PUT',
            body: JSON.stringify(parameter),
            signal: signal,
            headers: {
                ...headers,
                ...this.instance.headers
            }
        }
        
        if (abort && this.pendingRequests['put'][path]) {
            this.pendingRequests['put'][path].abort();
        }

        this.pendingRequests['put'][path] = abortController;

        const response = await fetch(this.baseUrl + path, this.instance);

        delete this.pendingRequests['put'][path];

        try {
            this.checkStatus(response.status);
        } catch(e) {
            throw e;
        }

        let body = await response.json();

        return body;
    }

    async delete(path: string, headers: any, abort: boolean = true): Promise<any> {
        this.auth();

        const abortController = new AbortController();
        const signal = abortController.signal;

        this.instance = {
            method: 'DELETE',
            signal: signal,
            headers: {
                ...headers,
                ...this.instance.headers
            }
        }
        
        if (abort && this.pendingRequests['delete'][path]) {
            this.pendingRequests['delete'][path].abort();
        }

        this.pendingRequests['delete'][path] = abortController;

        const response = await fetch(this.baseUrl + path, this.instance);

        delete this.pendingRequests['delete'][path];

        try {
            this.checkStatus(response.status);
        } catch(e) {
            throw e;
        }

        return null;
    }
}

export default new Requester();