import React, { useState, useEffect } from 'react';
import './LibraryList.scss';
import libraryAPI from '../../api/library';
import LibraryInterface from '../../models/library';
import LibraryItem from '../LibaryItem/LibraryItem';
import Loader from '../Loader/Loader';
import { useLibraryStore } from '../../stores';
import { observer } from 'mobx-react-lite';


export default observer(() => {
	const libraryStore = useLibraryStore();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);


    useEffect(() => {
        setIsLoading(true);
        libraryAPI.all().then((response) => {
			setIsLoading(false);
			libraryStore.set(response);
        });
    }, []);


    return (
        <Loader isLoading={isLoading}>
            <div className="library-list">
                <ul className="library-list__ul">
                    {libraryStore.libraries.map(library => (<LibraryItem key={library.id} library={library}/>))}
                </ul>
            </div>
        </Loader>
    )
});