import validator from 'validator';


/**
 * Returns either unmet rule, or null
 * @param value
 * @param validation
 * @returns {*}
 */
export default function validate(value, validation) {
    const fields = [];

    let trimmedValidation;
    let validatingFields;

    switch (typeof validation) {
        case 'object':
            Object.keys(validation).forEach(property => {
                fields.push({
                    rule: property,
                    options: validation[property]
                });
            });
            break;

        case 'string':
        default:
            if (!validation.length) return true;
            trimmedValidation = validation.replace(/ /g, '');
            validatingFields = trimmedValidation.split(',');
            validatingFields.forEach((fieldName) => {
                fields.push({
                    rule: fieldName
                });
            });
    }

    let isValid = true;

    fields.forEach((field) => {
        const { rule, options = null } = field

        switch (rule.trim()) {
            case 'isRequired':
                if (!value) isValid = false
                break
            default:
                if (isValid) {
                    if (options !== null) {
                        let result
                        switch (options) {
                            case true:
                                result = validator[rule](value);
                                break
                            case false:
                                result = !validator[rule](value);
                                break
                            default:
                                result = validator[rule](value, options);
                        }
                        isValid = result
                    } else isValid = validator[rule](value);
                    break
                }
        }
    });

    return isValid;
}