import React from 'react';
import './Loader.scss';

interface LoaderInterface {
    children: any,
    isLoading: boolean
}

export default ({ children, isLoading } : LoaderInterface) => {
    return (
        <>
            {isLoading ? 
                (
                    <div className="sk-chase">
                        <div className="sk-chase__dot"></div>
                        <div className="sk-chase__dot"></div>
                        <div className="sk-chase__dot"></div>
                        <div className="sk-chase__dot"></div>
                        <div className="sk-chase__dot"></div>
                        <div className="sk-chase__dot"></div>
                    </div>
                )
            : 
                children
            }
        </>
    )
}