import React, { FormEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from '../../../hooks';
import TextField from '../../TextField/TextField';
import Button from '../../Button/Button';
import userAPI from '../../../api/user';
import UserInterface from '../../../models/user';
import { observer } from 'mobx-react-lite';

export default observer(() => {
    const { values, useInput, isValid } = useForm({
        email: '',
        password: '',
        passwordConfirm: '',
        first_name: '',
        last_name: ''
    });

    const history = useHistory();

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!isValid) return;
        if (values.password !== values.passwordConfirm) return;

        userAPI.create(values).then((user: UserInterface) => {
            history.push('/login');
        });
    }

    return (
        <div className="login-form">
            <form onSubmit={onSubmit}>
                <TextField  
                        label="Email" 
                        name="email" 
                        required
                        type="email" 
                        useInput={useInput('email', {
                            isRequired: true,
                            isEmail: true
                        })}/>
                <TextField  
                        label="First Name" 
                        name="first_name" 
                        required
                        type="text" 
                        useInput={useInput('first_name', 'isRequired')}/>
                <TextField  
                        label="Last Name" 
                        name="last_name" 
                        required
                        type="text" 
                        useInput={useInput('last_name', 'isRequired')}/>
                <TextField  
                        label="Password" 
                        name="password" 
                        required
                        type="password" 
                        useInput={useInput('password', 'isRequired')}/>
                <TextField  
                        label="Confirm Password" 
                        name="passwordConfirm" 
                        required
                        type="password" 
                        useInput={useInput('passwordConfirm', 'isRequired')}/>
                <div className="login-form__form-footer">
                    <div className="login-form__form-footer__font">
                        <span className="login-form__form-footer__font__text">Sign Up</span>
                    </div>
                    <Button disabled={!isValid} isFloating={true} type="submit" icon={['fas', 'angle-right']}/>
                </div>
            </form>
            <div className="login-form__footer-links">
                <Link className="a a--black" to="/login">Sign in</Link>
                <Link className="a a--black" to="/forgot-password">Forgot password</Link>
            </div>
        </div>
    )
});