import React, { useState, useEffect } from 'react';
import Loader from '../Loader/Loader';
import shelfAPI from '../../api/shelf';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from 'react-router';
import { useShelfStore } from '../../stores';
import ShelfItem from '../ShelfItem/ShelfItem';

export default observer(() => {
	const [ isLoading, setIsLoading ] = useState(true);
	const shelfStore = useShelfStore();
	const history = useHistory();
	const { id } = useParams();
	if (!id) {
		history.push('/');
	}

	useEffect(() => {
		shelfAPI.all(id).then((response) => {
			setIsLoading(false);
			shelfStore.set(response);
		})
	}, []);

	return (
		<Loader isLoading={isLoading}>
            <div className="library-list">
                <ul className="library-list__ul">
					{shelfStore.shelves.map(shelf => (<ShelfItem key={shelf.id} libraryId={id} shelf={shelf}/>))}
                </ul>
            </div>
        </Loader>
	)
})