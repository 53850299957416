import React, {useEffect, useState} from 'react';
import Button from '../../components/Button/Button';
import ShelfList from '../../components/ShelfList/ShelfList';
import './ShelfBooks.scss';
import {useBookStore} from "../../stores";
import {useHistory, useParams} from "react-router";
import bookAPI from "../../api/book";
import BookList from "../../components/BookList/BookList";
import Loader from "../../components/Loader/Loader";
import {observer} from "mobx-react-lite";
import ShelfInterface from "../../models/shelf";
import ShelfWithBooks from "../../models/shelfwithbooks";

export default observer(() => {
    const bookStore = useBookStore();
    const history = useHistory();
    const {id, shelfId} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [shelf, setShelf] = useState<ShelfWithBooks>();


    if (!id) {
        history.push('/');
    }

    useEffect(() => {
        bookAPI.shelf(id, shelfId).then((response) => {
            setIsLoading(false);
            bookStore.set(response.books);
            setShelf(response);
        })
    }, []);

    return (
        <Loader isLoading={isLoading}>
            <div className="container">
                <div className="shelf-detail__headline">{shelf && shelf.shelf.name}</div>
                <BookList books={bookStore.books} libraryId={id} buttonLink={`/library/${id}/books`} buttonText="Alle Bücher in Bibliothek" />
                <div className="button-menu">
                    <Button className="shelf-button" isFloating icon={['fas', 'plus']}/>
                </div>
            </div>
        </Loader>
    );
})