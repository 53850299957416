import { observable, action } from 'mobx';

class GlobalStore {
    // padding documentation
    // 0 = default (login, register, view book)
    // 1 = smaller (small bar at the top)
    @observable padding: null | number = null;

    @action setPadding(padding: number) : void {
        this.padding = padding;
    }
}

export default new GlobalStore();