import React from 'react';
import ShelfInterface from '../../models/shelf';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import './ShelfItem.scss';

interface ShelfItemInterface {
	shelf: ShelfInterface,
	libraryId?: string
}

export default observer(({ shelf, libraryId } : ShelfItemInterface) => (
		<li className="library-item">
			<Link 
				className="a a--black a--bold library-item__link" 
				to={`/library/${libraryId}/shelves/${shelf.id}/books`}>
				<div className="library-item__label">
					<span className="library-item__label__font">
						{shelf.name}
					</span>
					<span className="library-item__label__count">
						<span className="b">Anzahl Bücher: </span>{shelf.numItems}
					</span>
				</div>
				
				<span className="library-item__menu">
					<button 
						className="reset-button shelf-menu__button library-item__menu__button">

						<FontAwesomeIcon 
							size="1x" 
							icon={['fal', 'angle-right']}/>
					</button>
				</span>
			</Link>
		</li>
	)
);