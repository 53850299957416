import { observable, action } from 'mobx';
import LoginRequestInterface from '../models/login-request';
import UserInterface from '../models/user';
import userAPI from '../api/user';
import { Cookies } from '../util';


class UserStore {
    @observable user: null | UserInterface = null;

    @action async login(user: LoginRequestInterface) : Promise<void> {
        const token = await userAPI.login(user);
        Cookies.set('token', token);
        this.user = await userAPI.me();
    }

    @action async load() : Promise<void> {
        this.user = await userAPI.me();
    }
        
    @action logout() {
        this.user = null;
        Cookies.remove('token');
    }
}


export default new UserStore();