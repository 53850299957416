import React from 'react';
import BookInterface from '../../models/book';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import './BookItem.scss';
import { Requester } from '../../util';

interface BookItemInterface {
	book: BookInterface,
	libraryId?: string
}

export default observer(({ book, libraryId } : BookItemInterface) => {
	const apiURL = Requester.baseUrl;

	return (
		<li className="book-item">
			<Link 
				className="a a--black book-item__link"
				to={`/book/${book.id}`}>
					<img className="book-item__image" alt={book.title} src={(apiURL ? apiURL : '') + (book.image.path ? book.image.path : '')}/>
					<div className="book-item__info" >
						<strong className="book-item__title">{book.title}</strong>
						{book.tagline && <div className="book-item__tagline">{book.tagline}</div>}
						{book.tags.map((tag) => (
								<span className="book-item__tag" >{tag}</span>
						))}
						<div className="book-item__detail-info">
							{book.authors && (
								<div className="book-item__detail-info-item">
									<strong className="book-item__detail-info-item-label">Autor: </strong>
									<span className="book-item__detail-info-item-value">{book.authors.join(', ')}</span>
								</div>
							)}
							{book.pages && (
								<div className="book-item__detail-info-item">
									<strong className="book-item__detail-info-item-label">Seiten: </strong>
									<span className="book-item__detail-info-item-value">{book.pages}</span>
								</div>
							)}
							{book.datePublished && (
								<div className="book-item__detail-info-item">
									<strong className="book-item__detail-info-item-label">Veröffentlicht: </strong>
									<span className="book-item__detail-info-item-value">{book.datePublished}</span>
								</div>
							)}
						</div>
					</div>
			</Link>
		</li>
	)
});