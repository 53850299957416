import React from 'react';
import RegisterForm from '../../components/Forms/Register/Register';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';

export default observer(() => (
    <div className="login-view">
        <div className="login-view__header">
            <h1 className="h1 login-view__header__h1 h1--white"><span className="light light--white">Welcome<br /></span></h1>
            <FontAwesomeIcon icon={['fas', 'book-open']} />
        </div>
        <div className="login-view__form-wrapper">
            <RegisterForm />
        </div>
    </div>
));