import { Requester } from '../util';
import LibraryInterface from '../models/library';

export default {
    async all() : Promise<any> { 
        const response = await Requester.get('/library', {});

        return response;
    },

    async create(library: LibraryInterface) : Promise<any> {
        const response = await Requester.post('/library', library, {});

        return response;
	},
	
	async update(library: LibraryInterface): Promise<any> {
		const response = await Requester.put(`/library/${library.id}`, library, {});

		return response;
	},

	async delete(id: string = ''): Promise<any> {
		const response = await Requester.delete(`/library/${id}`, {});

		return response;
	}
}