import { observable, action } from 'mobx';
import BookInterface from '../models/book';

class BookStore {

    @observable books: BookInterface[] = [];
		
	@action remove(id: string = '-1') {
		this.books.forEach((book, index) => {
			if (book.id === id) {
				this.books.splice(index);
			}
		});
	}

	@action setItem(book: BookInterface) {
		this.books.forEach((item, index) => {
			if (item.id === book.id) {
				this.books[index] = book;
			}
		});
	}

	@action add(book: BookInterface) {
		this.books.push(book);
	}

	@action set(books: BookInterface[]) {
		this.books = books;
	}
}

export default new BookStore();