import React, { useState } from 'react';
import './TextField.scss';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

interface TextFieldType {
    label: string,
    useInput: any,
    name: string, 
    required: boolean,
    type: string
}

export default observer(({label, useInput, name, required = false, type = 'text'}: TextFieldType) => {
    const { onBlur, onChange, onFocus, value, error } = useInput;

    const [ isFocusing, setIsFocusing ] = useState<boolean>(false);

    const onFocusHandler = (e: any) => {
        setIsFocusing(true);
        onFocus(e);
    }

    const onBlurHandler = (e: any) => {
        setIsFocusing(false);
        onBlur(e);
    }

    return (
        <div className="input-group">
            <label className={classNames({
                'input-group__label': true,
                'input-group__label--error': error
            })}>
                <div className={
                    classNames({
                        'input-group__label-text': true,
                        'input-group__label-text--error': error,
                        'input-group__label-text--focus': isFocusing || value !== ''
                    })
                }>{label}{required ? ' *' : ''}</div>
                <input 
                    type={type} 
                    className={classNames({
                        'input-group__input': true,
                        'input-group__input--error': error
                    })} 
                    name={name} 
                    onBlur={onBlurHandler}
                    onChange={onChange}
                    onFocus={onFocusHandler}
                    value={value}
                    />
            </label>
        </div>
    )
})
