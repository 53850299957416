import React from 'react';
import { observer } from 'mobx-react-lite';
import { useGlobalStore } from './stores';
import Router from './router';
import classNames from 'classnames';

export default observer(() => {
    const globalStore = useGlobalStore();

    return (
        <div 
            className={classNames({
                'app': true,
                'app--default-padding': globalStore.padding === 0,
                'app--smaller-padding': globalStore.padding === 1
            })}>
            <main className="app__content">
                <Router />
            </main>
        </div>
    );
});

